import { call } from '../@';

export type Payload = {
	transactionId: string;
	userId: string;
	transactionInvitationId: string;
};

export default async function cancelTransactionInvitation(payload: Payload) {
	return await call('transactionInvitation-cancel', payload);
}
