import { useQuestionnaire } from 'src/components/cards/useQuestionnaire';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { getPersonalQuestionnaire } from 'src/constants/surveyJson/personalQuestionnaire';
import { selectModal } from 'src/redux/slices/modal';
import { removeTask, selectTransactionParticipants } from 'src/redux/slices/transaction';
import { selectTransactionQuestionnaire } from 'src/redux/slices/transactionQuestionnaire';
import { dispatch, useSelector } from 'src/redux/store';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnaireTransactionEnum } from 'src/utils/types';

const Content = () => {
	const {
		payload: { userId, taskId },
	} = useSelector(selectModal<{ userId: string; taskId: string }>);
	const questionnaire = useSelector(selectTransactionQuestionnaire);
	const participants = useSelector(selectTransactionParticipants);
	const { onPageChanged, onComplete, onSubmit } = useQuestionnaire({
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
		type: QuestionnaireTransactionEnum.personal_details,
		suffix: `.${userId}.personal`,
	});

	const details = questionnaire?.personal_details?.[userId].personal;
	const user = participants.all.find((p) => p.uid === userId);

	if (!user) return null;

	const buyers = participants.all.filter((p) => p.role === 'buyer');

	const isBuyer = buyers.some((b) => b.uid === userId && (!b.relationship || b.relationship === 'buyer'));
	const isGiftor = buyers.some((b) => b.uid === userId && b.relationship === 'giftor');
	const isExtended =
		(user.role === 'seller' && user.relationship === 'proprietor') ||
		(user.role === 'buyer' && (!user.relationship || user.relationship === 'buyer') && user.side === 'buy');

	const completedQuestionnaire = fillQuestionnaireJsonWithCompletedData(
		getPersonalQuestionnaire(isExtended || isGiftor, isBuyer, isGiftor),
		details ?? {},
	);

	const handleComplete = async () => {
		dispatch(removeTask({ taskId, userId }));
		await onComplete();
	};

	return (
		<QuestionnarieModal
			questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
			questionnaireKey={QuestionnaireTransactionEnum.personal_details}
			onSubmit={onSubmit}
			onPageChanged={onPageChanged}
			surveyJson={completedQuestionnaire}
			title='PERSONAL DETAILS'
			onComplete={handleComplete}
			status={details?.status}
		/>
	);
};

const ConfirmDetailsModal = () => {
	const { isOpened, modalName } = useSelector(selectModal<{ questionnaireId: string; withSecondPage: boolean }>);

	const isQuestionnaireModalOpened = isOpened && modalName === QuestionnaireTransactionEnum.personal_details;

	if (!isQuestionnaireModalOpened) return null;

	return <Content />;
};

export default ConfirmDetailsModal;
