import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { useTask } from 'src/components/GeneralTab/components/TasksSection/components/Task/hooks/useTask';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { Task } from 'src/redux/types';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextFieldNumber } from 'src/components/hook-form';
import { getTaskRef } from 'src/utils/firebase';
import { updateDoc } from 'firebase/firestore';
import { getTransactionTrackerThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { snoozeTaskValidationSchema } from './validation';
import { SnoozeTaskForm } from './types';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const transaction = useSelector(selectTransactionOverview);
	const { payload } = useSelector(selectModal<Task>);
	const { description, statement } = useTask(payload);
	const form = useForm<SnoozeTaskForm>({
		resolver: yupResolver(snoozeTaskValidationSchema),
		defaultValues: { days: payload.snoozeDays },
	});

	const taskRef = getTaskRef(transaction.id, payload.id);

	const handleSubmit = async (data: SnoozeTaskForm) => {
		setIsLoading(true);

		await updateDoc<unknown>(taskRef, { snoozed: true, snoozeDays: data.days, snoozedAt: new Date() });
		dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));

		setIsLoading(false);
		handleCloseModal();
	};

	return (
		<Box>
			<FormProvider
				methods={form}
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle}>SET SNOOZE PERIOD</Typography>
				<Typography variant='subtitle1' mb='5px'>
					How long do you want to snooze "{description ?? statement}" task?
				</Typography>
				<RHFTextFieldNumber name='days' placeholder='Days' label='Number of days' />
				<Box display='flex' justifyContent='flex-end' gap='15px' alignItems='center' mt='20px'>
					<LoadingButton variant='outlined' onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton variant='contained' type='submit' loading={isLoading}>
						Snooze
					</LoadingButton>
				</Box>
			</FormProvider>
		</Box>
	);
};

const SnoozeTaskModal = () => (
	<Modal name={modals.snoozeTask} cardSx={{ maxWidth: '600px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default SnoozeTaskModal;
