import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import {
	selectTransactionFinance,
	selectTransactionOverview,
	selectTransactionParticipants,
} from 'src/redux/slices/transaction';
import { FC } from 'react';
import { giftorQuestions } from 'src/constants/surveyJson/giftorQuestions';
import { selectUser } from 'src/redux/slices/auth';
import { formatBidNumber } from 'src/utils/formatBid';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { useQuestionnaire } from '../useQuestionnaire';
import { GiftFinanceCardProps } from './types';

export const GiftFinanceCard: FC<GiftFinanceCardProps> = ({ gift }) => {
	const { key, amount, number, giftors } = gift;
	const participants = useSelector(selectTransactionParticipants);
	const transaction = useSelector(selectTransactionOverview);
	const user = useSelector(selectUser);
	const finance = useSelector(selectTransactionFinance);
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);

	const { onPageChanged, onSubmit, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: key as never,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	if (!fullQuestionnaire || isHidden || !transaction || !finance.data) return null;

	const questionnaire = fullQuestionnaire[key];
	const completedQuestionnaire = fillQuestionnaireJsonWithCompletedData(
		giftorQuestions(participants, user, finance.data, gift.giftorsIds),
		fullQuestionnaire[key],
	);

	const title = `Gift ${number}`;

	return (
		<>
			<QuestionnarieModal
				initialCurrentPage={questionnaire?.currentPage}
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				questionnaireKey={key as never}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedQuestionnaire}
				status={questionnaire?.status ?? QuestionnaireStatus.NOT_STARTED}
				title={title.toUpperCase()}
				isGiftorFinance
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title={`${title} ${
					user.isAdmin || transaction.isBuySide ? `/ £ ${formatBidNumber(amount)} / ${giftors.join(', ')}` : ''
				}`}
				type={key as never}
				isGiftorFinance
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			/>
		</>
	);
};
