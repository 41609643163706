import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { Task } from 'src/redux/types';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { getTaskRef } from 'src/utils/firebase';
import { getTransactionTrackerThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { updateDoc } from 'firebase/firestore';
import { taskStatus, taskType } from 'src/constants/tasks';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import createExchangePayments from 'src/api/transaction/create-exchange-payments';
import { StyledAlert } from 'src/hooks/styled';
import { call } from 'src/api/@';
import { errorCodes } from 'src/constants/common';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const transaction = useSelector(selectTransactionOverview);
	const { enqueueSnackbar } = useSnackbar();
	const { payload } = useSelector(selectModal<Task>);

	const taskRef = getTaskRef(transaction.id, payload.id);

	const handleContinue = async () => {
		try {
			setIsLoading(true);

			if (payload.type === taskType.hasExchanged) await createExchangePayments(transaction.id);

			await updateDoc<unknown>(taskRef, {
				status: taskStatus.done,
				completedAt: new Date(),
				...(!payload.dueDate && { dueDate: new Date() }),
			});

			await call('transaction-syncTasks', { id: transaction.id });

			dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));
			handleCloseModal();
		} catch (e) {
			const error = e as ExtendedError;
			console.log(error);

			if (error instanceof Error) {
				if (error.code === errorCodes.noOnboardedBuyers) {
					dispatch(openModal({ name: modals.noExchangeOnboardedUsers, payload: { type: 'buyers', task: payload } }));
				} else if (error.code === errorCodes.noOnboardedSellers) {
					dispatch(openModal({ name: modals.noExchangeOnboardedUsers, payload: { type: 'sellers', task: payload } }));
				} else {
					enqueueSnackbar(error.message, { variant: 'error' });
				}
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Typography sx={styledMainTitle}>
				{payload.type === taskType.hasExchanged ? 'EXCHANGED ON' : 'COMPLETED ON'}{' '}
				{moment(payload.dueDate).format('DD/MM/YYYY')}
			</Typography>
			<StyledAlert variant='filled' severity='warning'>
				This action cannot be undone
			</StyledAlert>
			<StyledAlert sx={{ mt: '7px' }} severity='info'>
				Not the right date? Press [Cancel] and set the task date first.
			</StyledAlert>
			<Typography variant='subtitle1' mb='7px' mt='7px'>
				{payload.type === taskType.hasExchanged
					? 'Marking this transaction as “Exchanged” will lock it and send final invoices to all participants.'
					: 'Marking this transaction as “Completed” will archive it.'}
			</Typography>
			<Typography variant='subtitle1' mb='7px'>
				Do you still want to proceed?
			</Typography>
			<Box display='flex' justifyContent='flex-end' gap='15px' alignItems='center' mt='20px'>
				<LoadingButton variant='outlined' onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' loading={isLoading} onClick={handleContinue}>
					Confirm
				</LoadingButton>
			</Box>
		</Box>
	);
};

const ConfirmTaskCompleteModal = () => (
	<Modal name={modals.confirmTaskCompleteModal} cardSx={{ maxWidth: '600px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default ConfirmTaskCompleteModal;
