import { dispatch, useSelector } from 'src/redux/store';
import {
	selectTransactionOverview,
	selectTransactionParticipants,
	selectTransactionParticipantsField,
	withdrawOffer,
} from 'src/redux/slices/transaction';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';
import { useMemo } from 'react';
import useLoading from 'src/hooks/useLoading';
import { NoBreak } from 'src/styled';
import { formatBidValue } from 'src/utils/formatBid';
import { selectUser } from 'src/redux/slices/auth';

const Content = () => {
	const transaction = useSelector(selectTransactionOverview);
	const user = useSelector(selectUser);
	const participants = useSelector(selectTransactionParticipants);

	const viewer = useMemo(
		() => participants.all.find(({ uid: id, side }) => side === 'view' && id === user.uid),
		[user.uid, participants],
	);

	if (!viewer?.offer) return null;

	return (
		<>
			Are you sure to withdraw your <b>{transaction.name}</b> offer for{' '}
			<NoBreak as='b'>£{formatBidValue(`${viewer.offer.price}`)}</NoBreak>?
		</>
	);
};

const WithdrawOfferModal = () => {
	const user = useSelector(selectUser);
	const { data: participants } = useSelector(selectTransactionParticipantsField);

	const { isLoading, setIsLoading } = useLoading();

	const viewer = useMemo(
		() => participants?.all.find(({ uid: id, side }) => side === 'view' && id === user.uid),
		[user.uid, participants?.participants],
	);

	const handleConfirm = async () => {
		if (!viewer?.offer) return;

		setIsLoading(true);

		await dispatch(withdrawOffer({ offerId: viewer.offer.id }));

		handleCloseModal();
		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			sx={{ minWidth: '550px' }}
			modalName={modals.withdrawOffer}
			title='WITHDRAW OFFER'
			confirmText='Confirm'
			handleConfirm={handleConfirm}
			cancelText='Cancel'
			description={<Content />}
			isLoading={isLoading}
		/>
	);
};

export default WithdrawOfferModal;
