/* eslint-disable react/no-unstable-nested-components */
import { Box, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Progress from 'src/components/Progress';
import { getMonthlyReportsThunk, selectMonthlyReports, sortMonthlyReports } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import Iconify from 'src/components/Iconify';
import useLoading from 'src/hooks/useLoading';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { downloadCsv } from 'src/utils/csv';
import moment from 'moment';
import { MonthlyReport } from 'src/redux/types';
import Table from 'src/components/common/Table';
import { TableColumn } from 'src/components/common/Table/types';
import { formatPrice } from 'src/utils/transaction/summary';
import Row from 'src/components/common/Table/components/Row';

const MonthlyTable = () => {
	const { data: reports, isLoading: areReportsLoading, error } = useSelector(selectMonthlyReports);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const [isCountMode, setIsCountMode] = useState(false);

	const handleSortChange = ({ order, orderBy }) => dispatch(sortMonthlyReports({ order, orderBy }));

	useEffect(() => {
		dispatch(getMonthlyReportsThunk());
	}, []);

	if (areReportsLoading) return <Progress />;
	if (error || !reports.data) {
		return (
			<Box p='15px'>
				<Typography color='red' variant='h4'>
					{error?.message ?? 'Something went wrong'}
				</Typography>
			</Box>
		);
	}

	const handleCountModeChange = () => setIsCountMode(!isCountMode);

	const columns: TableColumn<MonthlyReport>[] = isCountMode
		? [
				{ key: 'date', name: 'Month', sortable: true, width: 150 },
				{ key: 'quotedCount', name: 'Quoted count', sortable: true, width: 150 },
				{ key: 'bookedCount', name: 'Booked count', sortable: true, width: 150 },
				{ key: 'allocatedCount', name: 'Allocated count', sortable: true, width: 150 },
				{ key: 'lostCount', name: 'Lost count', sortable: true, width: 150 },
				{ key: 'openCount', name: 'Open count', sortable: true, width: 150 },
				{ key: 'dueCount', name: 'Due count', sortable: true, width: 150 },
				{ key: 'overdueCount', name: 'Overdue count', sortable: true, width: 150 },
				{ key: 'realisedCount', name: 'Completed count', sortable: true, width: 150 },
		  ]
		: [
				{ key: 'date', name: 'Month', sortable: true, width: 150 },
				{ key: 'quoted', name: 'Quoted', sortable: true, width: 150, getLabel: (r) => formatPrice(r.quoted) },
				{ key: 'booked', name: 'Booked', sortable: true, width: 150, getLabel: (r) => formatPrice(r.booked) },
				{ key: 'allocated', name: 'Allocated', sortable: true, width: 150, getLabel: (r) => formatPrice(r.allocated) },
				{ key: 'lost', name: 'Lost', sortable: true, width: 150, getLabel: (r) => formatPrice(r.lost) },
				{ key: 'open', name: 'Open', sortable: true, width: 150, getLabel: (r) => formatPrice(r.open) },
				{ key: 'due', name: 'Due', sortable: true, width: 150, getLabel: (r) => formatPrice(r.due) },
				{ key: 'overdue', name: 'Overdue', sortable: true, width: 150, getLabel: (r) => formatPrice(r.overdue) },
				{ key: 'realised', name: 'Realised', sortable: true, width: 150, getLabel: (r) => formatPrice(r.realised) },
		  ];

	const handleDownload = async () => {
		try {
			if (!reports.data) return;

			setIsLoading(true);

			const rows = [
				columns.map((c) => c.name),
				...[...reports.data.rows, reports.data.total].map((row) =>
					columns.map((c) => (c.getLabel ? c.getLabel(row) : row[c.key])),
				),
			];

			const date = moment().format('YYYY_MM_DD');

			downloadCsv(`monthly_report_${date}.csv`, rows);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Box mt='10px' display='flex' flexDirection='column' gap='7px'>
				<Typography fontSize='17px'>
					Display mode: <br />
					£<Switch onChange={handleCountModeChange} value={isCountMode} />#
				</Typography>
				<Typography fontSize='17px' variant='body2' color='grey' maxWidth='1000px'>
					All amounts are gross (= VAT-inclusive)
					<br />
					<br />
					Amounts are displayed in the month when the corresponding action took place. For instance, a transaction side
					counted in the Quoted total for Jan 23 might later appear in the Booked total for March 23, then in the
					Allocated total for April 23, and so on, reflecting the progression of the transaction over time.
				</Typography>
			</Box>
			<Table
				columns={columns}
				data={{
					data: {
						data: reports.data.rows,
						limit: 0,
						page: 0,
						totalCount: 0,
						totalPages: 0,
						sortOrder: reports.order,
						orderBy: reports.orderBy,
					},
					isInitialized: !isLoading,
				}}
				handleSortChange={handleSortChange}
				withPagination={false}
				rowKeyField='id'
				width={1750}
			>
				<Row columns={columns} row={reports.data.total} sx={{ background: 'lightgrey' }} />
			</Table>
			<Box pt='10px'>
				<LoadingButton variant='contained' onClick={handleDownload} loading={isLoading}>
					Download CSV
					<Iconify icon='mdi:file' fontSize={20} ml='5px' />
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default MonthlyTable;
