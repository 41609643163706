import { clearQuestionnaire as clearDocQuestionnaire } from 'src/api';
import { QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { getPropertyQuestionnaireRef, getTransactionQuestionnaireRef } from 'src/utils/firebase';
import { ClearDoneQuestionnaireParams } from './types';

export const clearDoneQuestionnaire = async ({
	questionnaireType,
	type,
	fullQuestionnaire,
	id,
}: ClearDoneQuestionnaireParams) => {
	if (fullQuestionnaire) {
		const isProperty = questionnaireType === QuestionnaireTypeEnum.PROPERTY;
		const questionnaireRef = isProperty ? getPropertyQuestionnaireRef(id) : getTransactionQuestionnaireRef(id);
		const questionnaireItemStatus = fullQuestionnaire[type]?.status;

		if (![QuestionnaireStatus.IN_PROGRESS, QuestionnaireStatus.DONE].includes(questionnaireItemStatus)) return;

		await clearDocQuestionnaire(questionnaireRef, type);

		return;
	}

	throw new Error("Questionnaire doesn't exist");
};
