/* eslint-disable no-return-await */
import { getAuth } from 'firebase/auth';
import type MethodArgsResultMap from './api-methods';
import HTTPMethod from './http-method.enum';

type MaybePromise<T> = T | PromiseLike<T>;

const functionsLink = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
const apiUrl = process.env.REACT_APP_API_URL;

const noBodyMethods: HTTPMethod[] = [HTTPMethod.get, HTTPMethod.head, HTTPMethod.options];
const defaultHeaders = { 'Content-Type': 'application/json' };

const methodsMap: {
	[method in keyof MethodArgsResultMap]: [
		httpMethod: HTTPMethod,
		requiresAuth: boolean,
		v2?: boolean,
		customSerializer?: (data: MethodArgsResultMap[method][0]) => MaybePromise<{
			body?: ReadableStream | Blob | BufferSource | FormData | string | undefined;
			headers?: { [x: string]: string };
			path?: string;
			query?: Partial<MethodArgsResultMap[method][0]>;
		}>,
		customDeserializer?: (res: Response) => MaybePromise<MethodArgsResultMap[method][1]>,
	];
} = {
	'transactionOnboarding-sync': [HTTPMethod.post, true, true, () => ({ path: `transaction-onboarding/sync` })],
	'transactionOnboarding-notification': [
		HTTPMethod.post,
		true,
		true,
		() => ({ path: `transaction-onboarding/report-issue` }),
	],

	'propertyOnboarding-notification': [
		HTTPMethod.post,
		true,
		true,
		() => ({ path: 'notifications/property-onboarding' }),
	],
	'propertyOnboarding-sync': [HTTPMethod.post, true, true, () => ({ path: `property-onboarding/sync` })],
	'propertyOnboarding-cancel': [HTTPMethod.post, true, true, () => ({ path: `property-onboarding/cancel` })],

	'property-add': [HTTPMethod.post, true, true, () => ({ path: 'property' })],
	'property-checkAlreadyOnSale': [HTTPMethod.post, true, true, () => ({ path: 'property/check-on-sale' })],
	'property-confirmTitleSelection': [HTTPMethod.post, true],
	'property-searchByUprn': [HTTPMethod.post, true, true, () => ({ path: 'property/search-by-uprn' })],

	'transactionInvitation-accept': [HTTPMethod.post, false],
	'transactionInvitation-reject': [HTTPMethod.post, true],
	'transactionInvitation-resend': [HTTPMethod.post, true],
	'transactionInvitation-cancel': [HTTPMethod.post, true],
	'transactionInvitation-invite': [HTTPMethod.post, true],

	'epc-findAddressByPostcode': [HTTPMethod.post, true, true, () => ({ path: 'epc/find-addresses' })],

	'yoti-issueNotification': [HTTPMethod.post, true, true, () => ({ path: `verification-session/report-issue` })],
	'yoti-verifyUser': [HTTPMethod.post, true, true, ({ userId }) => ({ path: `user/${userId}/verify` })],
	'yoti-verificationSession': [HTTPMethod.post, true, true, () => ({ path: 'verification-session/start' })],

	'permissions-getQuestionnaire': [HTTPMethod.get, true],

	'transactionQuestionnaire-getDynamic': [
		HTTPMethod.get,
		true,
		true,
		({ transactionId }) => ({ path: `transaction-questionnaire/${transactionId}/dynamic` }),
	],
	'transactionQuestionnaire-getFinance': [
		HTTPMethod.get,
		true,
		true,
		({ transactionId }) => ({ path: `transaction-questionnaire/${transactionId}/finance` }),
	],

	'sessions-start': [HTTPMethod.post, true, true, () => ({ path: 'session/start' })],
	'sessions-resume': [HTTPMethod.post, true, true, ({ sessionId }) => ({ path: `session/${sessionId}/resume` })],
	'sessions-end': [HTTPMethod.post, true, true, ({ sessionId }) => ({ path: `session/${sessionId}/end` })],

	'hmlr-searchPropertyTitles': [
		HTTPMethod.get,
		true,
		true,
		({ id, ...params }) => ({ path: `property/${id}/search-titles`, query: params }),
	],
	'hmlr-getPropertyTitles': [
		HTTPMethod.get,
		true,
		true,
		({ propertyId, isTransactionOnboarding }) => ({
			path: `property/${propertyId}/get-titles`,
			query: { isTransactionOnboarding },
		}),
	],
	'hmlr-getPropertyAvailableDocuments': [HTTPMethod.post, true],
	'hmlr-downloadPropertyDocuments': [HTTPMethod.post, true],

	'profilePicture-remove': [HTTPMethod.delete, true, true, () => ({ path: 'user/profile-picture' })],
	'profilePicture-upload': [
		HTTPMethod.post,
		true,
		true,
		({ file }) => {
			const formData = new FormData();

			formData.append('file', file);

			return { body: formData, path: 'user/profile-picture' };
		},
	],

	'user-getList': [HTTPMethod.get, true, true, () => ({ path: 'user' })],
	'user-changeEmailRequest': [HTTPMethod.post, true, true, () => ({ path: 'change-email-request' })],
	'user-verifyChangeEmailRequest': [
		HTTPMethod.post,
		true,
		true,
		({ requestId, ...body }) => ({
			path: `change-email-request/${requestId}/verify`,
			body: JSON.stringify(body),
			headers: defaultHeaders,
		}),
	],
	'user-approveChangeEmailRequest': [
		HTTPMethod.post,
		true,
		true,
		({ requestId, ...body }) => ({
			path: `change-email-request/${requestId}/approve`,
			body: JSON.stringify(body),
			headers: defaultHeaders,
		}),
	],
	'user-rejectChangeEmailRequest': [
		HTTPMethod.post,
		true,
		true,
		({ requestId, ...body }) => ({
			path: `change-email-request/${requestId}/reject`,
			body: JSON.stringify(body),
			headers: defaultHeaders,
		}),
	],
	'user-resetVerification': [
		HTTPMethod.post,
		true,
		true,
		({ userId }) => ({ path: `user/${userId}/reset-verification`, headers: defaultHeaders }),
	],
	'user-confirmChangeEmailRequest': [
		HTTPMethod.post,
		false,
		true,
		({ requestId, ...body }) => ({
			path: `change-email-request/${requestId}/confirm`,
			body: JSON.stringify(body),
			headers: defaultHeaders,
		}),
	],
	'user-cancelChangeEmailRequest': [
		HTTPMethod.post,
		true,
		true,
		({ requestId }) => ({ path: `change-email-request/${requestId}/cancel` }),
	],
	'user-resendChangeEmailRequestVerification': [
		HTTPMethod.post,
		true,
		true,
		({ requestId }) => ({ path: `change-email-request/${requestId}/resend-code` }),
	],
	'user-verificationUpdateNotification': [
		HTTPMethod.post,
		true,
		true,
		() => ({ path: 'notifications/verification-update' }),
	],
	'user-syncTasks': [HTTPMethod.post, true],

	'auth-registration': [HTTPMethod.post, false, true, () => ({ path: 'auth/register' })],
	'auth-validateEmail': [HTTPMethod.post, false, true, () => ({ path: 'auth/validate-email' })],
	'auth-checkUserNotExist': [HTTPMethod.post, false, true, () => ({ path: 'auth/check-user-not-exist' })],
	'auth-resetPassword': [HTTPMethod.post, false, true, () => ({ path: 'auth/reset-password' })],
	'auth-confirmResetPassword': [HTTPMethod.post, false, true, () => ({ path: 'auth/confirm-reset-password' })],
	'auth-confirmation': [HTTPMethod.post, true, true, () => ({ path: 'auth/confirm-code' })],
	'auth-resendVerificationCode': [HTTPMethod.post, true, true, () => ({ path: 'auth/resend-code' })],

	'bankAccounts-add': [HTTPMethod.post, true, true, () => ({ path: 'bank-account' })],
	'bankAccounts-get': [HTTPMethod.get, true, true, () => ({ path: 'bank-account' })],
	'bankAccounts-update': [HTTPMethod.put, true, true, ({ id }) => ({ path: `bank-account/${id}` })],
	'bankAccounts-uploadFiles': [
		HTTPMethod.post,
		true,
		true,
		({ id, files }) => {
			const formData = new FormData();

			files.forEach((file) => formData.append('files', file));

			return { path: `bank-account/${id}/files`, body: formData };
		},
	],

	'invitation-getUsers': [HTTPMethod.post, true],
	'invitation-getMetadata': [HTTPMethod.get, true],

	'reports-monthly': [HTTPMethod.get, true, true, () => ({ path: 'reports/monthly' })],
	'reports-progress': [HTTPMethod.get, true, true, () => ({ path: 'reports/progress' })],
	'reports-solicitors': [HTTPMethod.get, true, true, () => ({ path: 'reports/solicitors' })],

	'companies-get': [HTTPMethod.post, true, true, () => ({ path: 'company/list' })],
	'company-approve': [HTTPMethod.post, true, true, ({ id }) => ({ path: `company/${id}/approve` })],
	'company-reject': [HTTPMethod.post, true, true, ({ id }) => ({ path: `company/${id}/reject` })],
	'company-search': [HTTPMethod.post, true, true, () => ({ path: `company/search` })],
	'company-add': [HTTPMethod.post, true, true, () => ({ path: 'company' })],
	'company-update': [HTTPMethod.put, true, true, ({ companyId }) => ({ path: `company/${companyId}` })],
	'company-ask-for-help': [
		HTTPMethod.post,
		true,
		true,
		({ companyId }) => ({ path: `company/${companyId}/ask-for-help` }),
	],
	'company-branch-add': [HTTPMethod.post, true, true, ({ companyId }) => ({ path: `company/${companyId}/branch` })],
	'company-branch-reject': [
		HTTPMethod.post,
		true,
		true,
		({ id, companyId }) => ({ path: `company/${companyId}/branch/${id}/reject` }),
	],
	'company-branch-approve': [
		HTTPMethod.post,
		true,
		true,
		({ id, companyId }) => ({ path: `company/${companyId}/branch/${id}/approve` }),
	],
	'company-branch-update': [
		HTTPMethod.put,
		true,
		true,
		({ id, companyId }) => ({ path: `company/${companyId}/branch/${id}` }),
	],
	'company-branch-ask-for-help': [
		HTTPMethod.post,
		true,
		true,
		({ companyId, branchId }) => ({ path: `company/${companyId}/branch/${branchId}/ask-for-help` }),
	],

	'transaction-orderSalePack': [HTTPMethod.post, true],
	'transaction-sendCaseHandlerNotification': [
		HTTPMethod.post,
		true,
		true,
		({ id }) => ({ path: `transaction/${id}/send-case-handler-notification` }),
	],
	'transaction-update': [HTTPMethod.patch, true, true, ({ id }) => ({ path: `transaction/${id}` })],
	'transaction-updateUser': [
		HTTPMethod.put,
		true,
		true,
		({ id, userId }) => ({ path: `transaction/${id}/user/${userId}` }),
	],
	'transaction-syncTasks': [HTTPMethod.post, true],
	'transaction-createExchangePayments': [
		HTTPMethod.post,
		true,
		true,
		({ id, ...body }) => ({
			path: `transaction/${id}/create-exchange-payments`,
			body: JSON.stringify(body),
			headers: defaultHeaders,
		}),
	],
	'transaction-searchesUploadedNotification': [
		HTTPMethod.post,
		true,
		true,
		({ id, ...body }) => ({
			path: `transaction/${id}/searches-uploaded-notification`,
			body: JSON.stringify(body),
			headers: defaultHeaders,
		}),
	],
	'transaction-getTrail': [HTTPMethod.post, true],
	'transaction-add': [HTTPMethod.post, true],
	'transaction-scanQrCode': [HTTPMethod.post, true, true, () => ({ path: `transaction/scan-qr-code` })],
	'transaction-generateQrCode': [
		HTTPMethod.post,
		true,
		true,
		({ id }) => ({ path: `transaction/${id}/generate-qr-code` }),
	],
	'transaction-abandon': [HTTPMethod.post, true],
	'transaction-generateDocument': [HTTPMethod.post, true],
	'transaction-getOverview': [HTTPMethod.get, true, true, ({ id }) => ({ path: `transaction/${id}` })],
	'transaction-getParticipants': [HTTPMethod.get, true, true, ({ id }) => ({ path: `transaction/${id}/participants` })],
	'transaction-getSummary': [HTTPMethod.post, true],
	'transaction-getTracker': [HTTPMethod.get, true, true, ({ id }) => ({ path: `transaction/${id}/progress` })],
	'transaction-getSummaries': [HTTPMethod.get, true],
	'transaction-addPlaceholder': [HTTPMethod.post, true],
	'transaction-updatePlaceholder': [HTTPMethod.post, true],
	'transaction-removePlaceholder': [HTTPMethod.post, true],
	'transaction-selectTitle': [HTTPMethod.post, true],
	'property-askTitleSelectionHelp': [
		HTTPMethod.post,
		true,
		true,
		({ propertyId }) => ({ path: `property/${propertyId}/ask-title-selection-help` }),
	],
	'transaction-sync': [HTTPMethod.post, true],
	'transaction-uploadPlaceholderVerificationDocument': [
		HTTPMethod.post,
		true,
		false,
		({ file, type, transactionId, placeholderId }) => ({
			body: file,
			headers: { 'Content-Type': type, 'X-Transaction-Id': transactionId, 'X-Placeholder-Id': placeholderId },
		}),
	],
	'transaction-createEpcExpirationTask': [HTTPMethod.post, true],
	'transaction-getDeed': [HTTPMethod.post, true],
	'transaction-signDeed': [HTTPMethod.post, true],

	'payment-request': [HTTPMethod.post, true, true, () => ({ path: 'payment' })],
	'payment-test': [HTTPMethod.post, true, true, () => ({ path: 'payment/test' })],

	'documents-get': [HTTPMethod.post, true],
	'documents-updateFolder': [HTTPMethod.post, true],
	'documents-deleteFolder': [HTTPMethod.post, true],
	'documents-addFolder': [HTTPMethod.post, true],
	'documents-delete': [HTTPMethod.delete, true],
	'documents-upload': [
		HTTPMethod.post,
		true,
		false,
		({ file, location, type, folderId, propertyId, name, taskType }) => ({
			body: file,
			headers: {
				'Content-Type': type,
				'X-Folder-Id': folderId,
				'X-Location': location,
				'X-Property': propertyId,
				'X-Name': name,
				...(taskType && { 'X-Task-Type': taskType }),
			},
		}),
	],

	// legacy
	invitation: [HTTPMethod.post, true],
	acceptInvitation: [HTTPMethod.post, true],
	rejectInvitation: [HTTPMethod.post, true],
	revokeInvitation: [HTTPMethod.post, true],
	resendInvitation: [HTTPMethod.post, true],
};

export default async function call<M extends keyof MethodArgsResultMap>(
	method: M,
	args: MethodArgsResultMap[M][0],
	opts: Partial<RequestInit> = {},
): Promise<MethodArgsResultMap[M][1]> {
	const [httpMethod, requiresAuth, v2, customSerializer, customDeserializer] = methodsMap[method];
	const serialized = customSerializer ? await customSerializer(args) : null;

	const url = new URL(`${v2 ? apiUrl : functionsLink}/${serialized?.path ?? method}`);
	const headers: { [x: string]: string } = {};
	let body;
	if (requiresAuth) {
		const { currentUser } = getAuth();
		if (!currentUser) throw new Error(`Method ${method} requires user to be authenticated first`);
		headers.Authorization = `Bearer ${await currentUser.getIdToken()}`;
	}
	if (noBodyMethods.includes(httpMethod)) {
		const queryArgs = serialized?.query ?? args;

		Object.keys(queryArgs).forEach((argName) => {
			if (queryArgs[argName] === undefined || queryArgs[argName] === null) return;
			url.searchParams.append(argName, String(queryArgs[argName]));
		});
	} else if (serialized?.body) {
		body = serialized.body;

		if (serialized.headers) {
			Object.keys(serialized.headers).forEach((header) => {
				if (!serialized.headers) return;

				headers[header] = serialized.headers[header];
			});
		}
	} else {
		body = JSON.stringify(args);
		headers['Content-Type'] = 'application/json';
	}
	const fetchOptions: RequestInit & { duplex?: string } = {
		method: httpMethod,
		body,
		headers,
		...opts,
	};
	if (body && body instanceof ReadableStream) {
		fetchOptions.duplex = 'half';
	}
	const res = await fetch(url, fetchOptions);
	if (customDeserializer) return await customDeserializer(res);
	if (res.ok) return await res.json();

	if (v2) {
		const data = await res.json();
		const e = new Error(data.message) as Error & { code: number };
		e.code = data.statusCode;

		throw e;
	}

	const e = new Error(await res.text()) as Error & { code: number };
	e.code = res.status;
	throw e;
}
