import { openModal, selectModal } from 'src/redux/slices/modal';
import { Deed, Task } from 'src/redux/types';

import { useSelector } from 'react-redux';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { Box, Typography } from '@mui/material';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { useEffect, useMemo, useState } from 'react';
import getTransactionDeed from 'src/api/transaction/get-transaction-deed';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { LoadingButton } from '@mui/lab';
import useError from 'src/hooks/useError';
import Progress from 'src/components/Progress';
import { getDownloadURL, ref } from 'firebase/storage';
import { storages } from 'src/api/@';
import { useSnackbar } from 'notistack';
import { dispatch } from 'src/redux/store';
import { removeTask, selectTransactionOverview } from 'src/redux/slices/transaction';
import { selectUser } from 'src/redux/slices/auth';
import signTransactionDeed from 'src/api/transaction/sign-transaction-deed';
import { ExtendedDeedDocument } from './types';
import Document from './components/Document';

const Content = () => {
	const user = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const { error, setError } = useError();
	const { isLoading: isDeedLoading, setIsLoading: setIsDeedLoading } = useLoading();
	const { payload } = useSelector(selectModal<{ task: Task; viewOnly: boolean; shouldInstantlySign?: boolean }>);
	const [deed, setDeed] = useState<Deed | null>(null);
	const [documents, setDocuments] = useState<ExtendedDeedDocument[]>([]);

	useEffect(() => {
		const setup = async () => {
			try {
				setIsDeedLoading(true);

				if (!payload.task.metadata) return;

				const data = await getTransactionDeed(transaction.id, payload.task.metadata.deedId);

				const extendedDocuments: ExtendedDeedDocument[] = await Promise.all(
					data.documents.map(async (doc) => {
						const publicUrl = await getDownloadURL(ref(storages.transactions, doc.filePath));

						return { ...doc, publicUrl, isLoading: true, isErrored: false };
					}),
				);

				setDocuments(extendedDocuments);
				setDeed(data);
			} catch (e) {
				if (e instanceof Error) setError(e.message);
			} finally {
				setIsDeedLoading(false);
			}
		};

		setup();
	}, [payload.task.metadata]);

	const cancelButton = (
		<Box display='flex' flexDirection='row' gap='15px' mt='15px' justifyContent='flex-end'>
			<LoadingButton variant='contained' size='large' onClick={handleCloseModal}>
				Cancel
			</LoadingButton>
		</Box>
	);

	const handleSign = async () => {
		try {
			if (!user.signature) {
				dispatch(openModal({ name: modals.enterSignature, payload: { task: payload.task } }));

				return;
			}

			setIsLoading(true);

			await signTransactionDeed(transaction.id, payload.task.metadata.deedId);

			enqueueSnackbar('Documents signed successfully');
			handleCloseModal();
			dispatch(removeTask({ taskId: payload.task.id, userId: user.uid }));
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const isAnyDocumentLoading = useMemo(() => documents.some((d) => d.isLoading), [documents]);
	const isAnyDocumentErrored = useMemo(() => documents.some((d) => d.isErrored), [documents]);

	const onDocumentFinishLoading = (index: number) => {
		const updatedDocuments = documents.map((d, i) => (i === index ? { ...d, isLoading: false } : d));

		setDocuments(updatedDocuments);
	};
	const onDocumentError = (index: number) => {
		const updatedDocuments = documents.map((d, i) => (i === index ? { ...d, isErrored: true } : d));

		setDocuments(updatedDocuments);
	};

	return (
		<Box>
			<Typography sx={styledMainTitle}>{payload.viewOnly ? 'REVIEW' : 'REVIEW & SIGN'}</Typography>
			{error}
			{deed ? (
				deed.isSignedByCurrentUser ? (
					<Box>
						<Typography variant='subtitle1'>Deed is already signed, no action needed now</Typography>
						{cancelButton}
					</Box>
				) : documents.length ? (
					<Box display='flex' flexDirection='column' gap='15px'>
						{documents.map((d, index) => (
							<Document
								key={d.filePath}
								{...d}
								onDocumentError={onDocumentError}
								onDocumentFinishLoading={onDocumentFinishLoading}
								index={index}
							/>
						))}
						{!payload.viewOnly && (
							<>
								<Typography variant='subtitle1' textAlign='center' px='30px'>
									By signing these documents, you confirm that the information provided is accurate to the best of your
									knowledge.
								</Typography>
								<Box display='flex' flexDirection='row' gap='15px' mt='15px' justifyContent='flex-end'>
									<LoadingButton
										variant='outlined'
										size='large'
										onClick={handleCloseModal}
										loading={isLoading}
										disabled={isAnyDocumentLoading}
									>
										Cancel
									</LoadingButton>
									<LoadingButton
										variant='contained'
										size='large'
										onClick={handleSign}
										loading={isLoading}
										disabled={isAnyDocumentErrored || isAnyDocumentLoading}
									>
										Sign
									</LoadingButton>
								</Box>
							</>
						)}
					</Box>
				) : (
					<Box>
						<Typography variant='subtitle1'>Something went wrong while loading documents, try again later</Typography>
						{cancelButton}
					</Box>
				)
			) : isDeedLoading ? (
				<Box py='75px'>
					<Progress />
				</Box>
			) : (
				cancelButton
			)}
		</Box>
	);
};

const SignDeedModal = () => (
	<Modal
		name={modals.signDeed}
		cardSx={{
			maxWidth: '1200px',
			padding: '32px 27px',
			overflow: 'auto',
			maxHeight: '93vh',
			outline: 'none',
			borderRadius: '7px',
		}}
		modalSx={{ overflow: 'hidden' }}
	>
		<Content />
	</Modal>
);

export default SignDeedModal;
