import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { scanTransactionQrCode } from 'src/api';
import LoadingScreen from 'src/components/LoadingScreen';
import { selectIsAuthenticated, selectIsInitialized } from 'src/redux/slices/auth';
import { PATH_DASHBOARD } from 'src/routes/paths';

const TransactionQrCode = () => {
	const navigate = useNavigate();
	const isInitialized = useSelector(selectIsInitialized);
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const { id } = useParams();

	useEffect(() => {
		const setup = async () => {
			if (!id) {
				navigate('/auth/login');

				return;
			}
			if (!isInitialized) return;

			sessionStorage.setItem('transactionQrCodeId', id);

			if (isInitialized && !isAuthenticated) {
				sessionStorage.setItem('redirectTo', window.location.href);

				navigate('/auth/login');

				return;
			}

			await scanTransactionQrCode();

			navigate(PATH_DASHBOARD.root);
		};

		setup();
	}, [isInitialized, id]);

	return <LoadingScreen />;
};

export default TransactionQrCode;
