import { call } from '../@';

export type Payload = {
	transactionId: string;
	userId: string;
	transactionInvitationId: string;
};

export default async function resendTransactionInvitation(payload: Payload) {
	return await call('transactionInvitation-resend', payload);
}
