import { useSelector } from 'react-redux';
import {
	clearDonePropertyQuestionnaire,
	refreshPropertyQuestionnaire,
	selectPropertyQuestionnaireSlice,
	updatePropertyQuestionnaire,
} from 'src/redux/slices/propertyQuestionnaire';
import {
	clearDoneTransactionQuestionnaire,
	refreshTransactionQuestionnaire,
	selectTransactionQuestionnaireSlice,
	updateTransactionQuestionnaire,
} from 'src/redux/slices/transactionQuestionnaire';
import { dispatch } from 'src/redux/store';
import { IQuestionnaire, QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { getPropertyQuestionnaire, getTransactionQuestionnaire } from 'src/utils/firebase';
import { get, noop } from 'lodash';
import { useMemo } from 'react';
import { selectTransactionOverviewField } from 'src/redux/slices/transaction';
import { useLocation } from 'react-router';
import { QuestionnairePropertyEnum, QuestionnaireTransactionEnum } from 'src/utils/types';
import { selectUser } from 'src/redux/slices/auth';
import { getTransactionInterest } from 'src/pages/transactions/utils/common';
import { selectPropertySlice } from 'src/redux/slices/property';
import { mergeQuestionnaireUpdateData } from './utils';
import { UpdateQuestionnaireData, UseQuestionnaireParams } from './types';

export const useQuestionnaire = ({ type, questionnaireType, onUpdate = noop, suffix = '' }: UseQuestionnaireParams) => {
	const { pathname } = useLocation();
	const user = useSelector(selectUser);
	const { fullQuestionnaire: propertyQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { fullQuestionnaire: transactionQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const { data: transaction } = useSelector(selectTransactionOverviewField);
	const { property } = useSelector(selectPropertySlice);

	const key = `${type}${suffix}`;
	const isProperty = questionnaireType === QuestionnaireTypeEnum.PROPERTY;
	const isTransaction = questionnaireType === QuestionnaireTypeEnum.TRANSACTION;
	const currentQuestionnaire = get(isTransaction ? transactionQuestionnaire : propertyQuestionnaire, key);
	const isTransactionPage = pathname.startsWith('/transactions');
	const isStarted = currentQuestionnaire?.status !== QuestionnaireStatus.NOT_STARTED;
	const buySideGifts = (transactionQuestionnaire?.buyer_questions?.funds?.gift ?? []).filter((g) => !!g.id);

	const isHidden = useMemo(() => {
		if (!isTransactionPage) return false;

		if (transaction?.isBuyerGiftor) {
			const [, id] = key.split('gift_');

			const buySideGift = buySideGifts.find((g) => g.id === id);

			if (!buySideGift) return true;

			return !buySideGift.giftors.includes(user.uid);
		}

		if (transaction?.isBuyer) {
			const isBuySide =
				key === QuestionnaireTransactionEnum.buyer_questions || key === QuestionnaireTransactionEnum.moving_in;

			return !isBuySide && !isStarted;
		}

		return false;
	}, [key, transaction, isTransactionPage]);

	const updateData = async (data: UpdateQuestionnaireData) => {
		if (isProperty && property) {
			const { data: newestData } = await getPropertyQuestionnaire(property.id);

			const questionnaireData = get(newestData, key) as undefined | IQuestionnaire;
			const updatedData = mergeQuestionnaireUpdateData(questionnaireData, data);

			onUpdate(updatedData);

			await dispatch(
				updatePropertyQuestionnaire({
					type: key,
					data: {
						...mergeQuestionnaireUpdateData(questionnaireData, data),
						status: data.status ?? questionnaireData?.status,
					},
				}),
			);
			dispatch(refreshPropertyQuestionnaire());
		}
		if (isTransaction && transaction) {
			console.log(33434);
			const { data: newestData } = await getTransactionQuestionnaire(transaction?.id);
			console.log(111);

			const questionnaireData = get(newestData, key) as undefined | IQuestionnaire;
			const updatedData = mergeQuestionnaireUpdateData(questionnaireData, data);

			onUpdate(updatedData);

			console.log(343);

			await dispatch(
				updateTransactionQuestionnaire({
					type: key,
					data: { ...updatedData, status: data.status ?? questionnaireData?.status },
				}),
			);
			dispatch(refreshTransactionQuestionnaire());
		}
	};

	const fromArrToObj = (acc, { name, value, comment, jsonObj, isOtherSelected }) => {
		if (jsonObj.type === 'radiogroup') {
			return { ...acc, [name]: isOtherSelected ? comment : value };
		}

		if (jsonObj.type === 'tagbox' || jsonObj.type === 'dropdown' || jsonObj.type === 'checkbox') {
			return {
				...acc,
				[name]: Array.isArray(value)
					? value.map((v) => {
							if (v === 'other' && comment && isOtherSelected) return comment;

							return v;
					  }) ?? []
					: value,
			};
		}

		return typeof value === 'undefined' ? acc : { ...(acc || {}), [name]: value, isSubmitted: true };
	};

	const onPageChanged =
		(currentPage, isLocked) =>
		async (_, { oldCurrentPage: { name, questions }, isNextPage }) => {
			const data = get(isTransaction ? transactionQuestionnaire : propertyQuestionnaire, key);
			const newStatus =
				data?.status === QuestionnaireStatus.DONE ? QuestionnaireStatus.DONE : QuestionnaireStatus.IN_PROGRESS;

			if (isLocked) return;

			if (currentPage === null) {
				if (questionnaireType === QuestionnaireTypeEnum.PROPERTY) await dispatch(clearDonePropertyQuestionnaire(type));
				if (questionnaireType === QuestionnaireTypeEnum.TRANSACTION)
					await dispatch(clearDoneTransactionQuestionnaire(type));
			}
			const pageAnswers = questions.reduce(fromArrToObj, null);
			const newPage = isNextPage ? currentPage + 1 : currentPage - 1;

			if (pageAnswers) {
				await updateData({ [name]: pageAnswers, currentPage: newPage, status: newStatus });

				return;
			}

			await updateData({ currentPage: newPage, status: newStatus });
		};

	const onSubmit = async ({ propertyHash, pages }) => {
		const lastPage = pages.find((item) => item.name === propertyHash.activePage?.name);
		const pageAnswers = lastPage?.questions.reduce(fromArrToObj, null) ?? {};

		await updateData({
			[lastPage.name]: pageAnswers,
			status:
				currentQuestionnaire?.status === QuestionnaireStatus.DONE
					? QuestionnaireStatus.DONE
					: QuestionnaireStatus.IN_PROGRESS,
		});
	};

	const onComplete = async () => {
		await updateData({ status: QuestionnaireStatus.DONE, isSubmitted: true });
	};

	const { isNotAllowed, notAllowedMessage } = useMemo(() => {
		if (!transaction) return { isNotAllowed: false, notAllowedMessage: null };

		const interest = getTransactionInterest(transaction, propertyQuestionnaire);
		const isOwnershipQuestionnaireVisible = !(transaction.tenure !== 'leasehold' && interest !== 'Share of freehold');

		const shouldQuestionnaireBeChecked = [
			QuestionnairePropertyEnum.building_works,
			QuestionnairePropertyEnum.rights_arrangements,
			QuestionnairePropertyEnum.guarantees_insurance,
			QuestionnaireTransactionEnum.liabilities,
		].includes(type as never);

		if (shouldQuestionnaireBeChecked) {
			const isPropertyDetailsCompleted = propertyQuestionnaire?.property_details.status === QuestionnaireStatus.DONE;
			const isOwnershipCompleted = isOwnershipQuestionnaireVisible
				? propertyQuestionnaire?.ownership.status === QuestionnaireStatus.DONE
				: true;

			if (!isPropertyDetailsCompleted || !isOwnershipCompleted) {
				return {
					isNotAllowed: true,
					notAllowedMessage: (
						<>
							You must complete the{' '}
							{!isPropertyDetailsCompleted && !isOwnershipCompleted
								? 'Property Details and Management cards'
								: isOwnershipCompleted
								? 'Property Details card'
								: 'Management card'}{' '}
							first.
						</>
					),
				};
			}
		}

		return { isNotAllowed: false, notAllowedMessage: null };
	}, [type, transaction, propertyQuestionnaire]);

	return {
		onPageChanged,
		onSubmit,
		onComplete,
		updateData,
		isHidden,
		isNotAllowed,
		notAllowedMessage,
	};
};
