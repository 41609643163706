import moment from 'moment';
import { MonthlyReport, SortableRow } from 'src/redux/types';
import { formatPrice } from 'src/utils/transaction/summary';

export const getMonthlyReportSortableRow = (row: MonthlyReport): SortableRow<MonthlyReport> => ({
	date: { label: moment(row.date).format('YYYY MM'), value: new Date(row.date).getTime() },
	quoted: { label: formatPrice(row.quoted), value: row.quoted },
	booked: { label: formatPrice(row.booked), value: row.booked },
	allocated: { label: formatPrice(row.allocated), value: row.allocated },
	lost: { label: formatPrice(row.lost), value: row.lost },
	open: { label: formatPrice(row.open), value: row.open },
	due: { label: formatPrice(row.due), value: row.due },
	overdue: { label: formatPrice(row.overdue), value: row.overdue },
	realised: { label: formatPrice(row.realised), value: row.realised },
	allocatedCount: { label: row.allocatedCount, value: row.allocatedCount },
	bookedCount: { label: row.bookedCount, value: row.bookedCount },
	dueCount: { label: row.dueCount, value: row.dueCount },
	lostCount: { label: row.lostCount, value: row.lostCount },
	openCount: { label: row.openCount, value: row.openCount },
	overdueCount: { label: row.overdueCount, value: row.overdueCount },
	quotedCount: { label: row.quotedCount, value: row.quotedCount },
	realisedCount: { label: row.realisedCount, value: row.realisedCount },
});
