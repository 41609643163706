import { transactionRoles, transactionUserRelationship } from 'src/pages/transactions/constants';
import palette from 'src/theme/palette';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import { UserData } from 'src/api/auth/@user-data';
import {
	IExtendedQuestionnaireTransaction,
	IQuestionnaireTransaction,
	NotistackAlert,
	QuestionnaireStatus,
	VerificationSessionStatus,
} from 'src/types';
import {
	acceptTransactionInvitation,
	getTransactionOverviewThunk,
	getTransactionParticipantsThunk,
	processTransactionOnboarding,
	rejectAskPrice,
	rejectOffer,
	rejectTransactionInvitation,
	setParticipantsLoading,
} from 'src/redux/slices/transaction';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { ITransactionOverview } from 'src/redux/types';
import modals from 'src/constants/modals';
import syncTransactionOnboarding from 'src/api/transaction-onboarding/sync';
import { findTransactionOnboarding } from 'src/utils/firebase/transaction-onboarding';
import { Typography } from '@mui/material';
import { GetVerified } from './styles';
import { IInviteCardTooltipFields, LocalUser, StatusBarType } from './types';

export const getUserName = (user: LocalUser) => {
	if (user.role !== 'seller' && user.role !== 'buyer') return `${user.knownAs ?? user.givenNames} ${user.lastNames}`;

	if (user.givenNames) return `${user.givenNames} ${user.lastNames}`;

	return user.email;
};

export const getUserRoleName = (user: LocalUser) => {
	if (user.role === 'buyer' && user.side === 'view') return 'Prospective Buyer';
	if (user.role === 'agent' && user.side === 'sell' && !user.relationship) return 'Selling Agent';
	if (user.role === 'agent' && user.side === 'buy' && !user.relationship) return 'Buying Agent';

	if (user.relationship) {
		const relationship = transactionUserRelationship[user.relationship];

		if (relationship) return relationship.shortName;
	}

	return transactionRoles[user.role]?.name ?? 'Unknown role';
};

export const getUserBusiness = (user: Pick<LocalUser, 'business' | 'branch'>) => {
	const business = {
		name: '',
	};
	const branch = {
		name: '',
		address: '',
		logo: '',
	};

	if (typeof user.branch === 'string') {
		branch.name = user.branch;
	} else if (user.branch) {
		branch.name = user.branch.name;
		if (user.branch.address) {
			branch.address = user.branch.address.formattedAddress;
		}
		if (user.branch.logo) {
			branch.logo = user.branch.logo;
		}
	}

	if (typeof user.business === 'string') {
		business.name = user.business;
	} else if (user.business) {
		business.name = user.business.tradingName;
	}

	return { business, branch };
};

export const getStatusBar = (
	transaction: ITransactionOverview,
	currentUser: UserData,
	user: LocalUser,
	questionnaire: IExtendedQuestionnaireTransaction,
	alert: NotistackAlert,
): StatusBarType => {
	const isCurrentUser = currentUser.uid === user.uid;
	const isViewer = user.side === 'view' && user.role === 'buyer';
	const hasCurrentUserFailedVerification =
		currentUser.verificationStatus === VerificationSessionStatus.FAILED ||
		currentUser.verificationStatus === VerificationSessionStatus.NAME_MISMATCH ||
		currentUser.verificationStatus === VerificationSessionStatus.ADDRESS_MISMATCH;
	const personalDetails = questionnaire?.personal_details?.[user.uid];

	if (user.isPlaceholder) {
		return { isVisible: true, color: palette.light.grey[400], title: <>Placeholder</> };
	}

	const handleVerification = async () => {
		dispatch(setParticipantsLoading(true));

		const onboardingSnapshot = await findTransactionOnboarding(currentUser.uid, transaction.id);

		if (!onboardingSnapshot) await syncTransactionOnboarding(transaction.id, 'verification');

		dispatch(processTransactionOnboarding({ transactionId: transaction.id }));

		dispatch(setParticipantsLoading(false));
	};
	const handleRetryVerification = async () => {
		dispatch(setParticipantsLoading(true));

		await syncTransactionOnboarding(transaction.id, 'verification');

		dispatch(processTransactionOnboarding({ transactionId: transaction.id }));

		dispatch(setParticipantsLoading(false));
	};

	const handleAcceptTransactionInvitation = async () => {
		await dispatch(
			acceptTransactionInvitation({
				transactionId: transaction.id,
				userId: user.uid,
				transactionInvitationId: user.invitationId as string,
			}),
		).unwrap();
		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	};
	const handleRejectTransactionInvitation = async () => {
		await dispatch(
			rejectTransactionInvitation({
				transactionId: transaction.id,
				userId: user.uid,
				transactionInvitationId: user.invitationId as string,
			}),
		).unwrap();
		window.location.href = PATH_DASHBOARD.root;
	};

	const handleAcceptOfferByViewer = async () => {
		if (!user.counterOffer) return;

		dispatch(openModal({ name: modals.acceptCounteroffer, payload: { user, offer: user.counterOffer } }));
	};
	const handleCounterofferByViewer = () => {
		if (!user.offer) return;

		dispatch(
			openModal({
				name: modals.counterOffer,
				payload: { user, offer: user.counterOffer, isViewSide: true },
			}),
		);
	};
	const handleRejectOfferByViewer = async () => {
		if (!user.counterOffer) return;

		await dispatch(rejectOffer({ offerId: user.counterOffer.id }));
		alert('Offer rejected!');
	};

	const handleAcceptOfferBySeller = async () => {
		if (!user.offer) return;

		dispatch(openModal({ name: modals.acceptOffer, payload: { user, offer: user.offer } }));
	};
	const handleCounterofferBySeller = () => {
		if (!user.offer) return;

		dispatch(
			openModal({
				name: modals.counterOffer,
				payload: { user, offer: user.offer },
			}),
		);
	};
	const handleRejectOfferBySeller = async () => {
		if (!user.offer) return;

		await dispatch(rejectOffer({ offerId: user.offer.id }));
		alert('Offer rejected!');
	};

	if (!user.approved && isCurrentUser) {
		return {
			isVisible: true,
			color: palette.light.primary.neonYellow,
			title: (
				<GetVerified>
					Invite pending:{' '}
					<button className='link' type='button' onClick={handleAcceptTransactionInvitation}>
						Accept
					</button>{' '}
					|{' '}
					<button className='link' type='button' onClick={handleRejectTransactionInvitation}>
						Reject
					</button>
				</GetVerified>
			),
		};
	}

	if (!user.approved) {
		return {
			isVisible: true,
			color: palette.light.primary.main,
			title: 'Invite pending',
			textColor: 'white',
		};
	}

	if (!user.isActive) {
		return {
			isVisible: true,
			color: palette.light.primary.main,
			title: 'Registration pending',
			textColor: 'white',
		};
	}

	if (
		!isViewer &&
		isCurrentUser &&
		(currentUser.verificationStatus === VerificationSessionStatus.PENDING ||
			!currentUser.isNameLocked ||
			(currentUser.isPrincipal && !currentUser.isAddressLocked))
	) {
		return {
			isVisible: true,
			color: palette.light.primary.neonYellow,
			title: (
				<GetVerified>
					Verification pending:{' '}
					<button className='link' type='button' onClick={handleVerification}>
						click here
					</button>
				</GetVerified>
			),
		};
	}

	if (
		!isViewer &&
		isCurrentUser &&
		hasCurrentUserFailedVerification &&
		currentUser.verificationSession?.retries !== 1
	) {
		return {
			isVisible: true,
			color: palette.light.primary.neonYellow,
			title: (
				<GetVerified>
					Verification failed:{' '}
					<button className='link' type='button' onClick={handleRetryVerification}>
						please try again here
					</button>
				</GetVerified>
			),
		};
	}

	if (!isViewer && isCurrentUser && hasCurrentUserFailedVerification) {
		return {
			isVisible: true,
			color: palette.light.primary.neonYellow,
			title: <GetVerified>Verification failed</GetVerified>,
		};
	}

	if (!isViewer && isCurrentUser && currentUser.verificationStatus === VerificationSessionStatus.IN_PROGRESS) {
		return {
			isVisible: true,
			color: palette.light.primary.neonYellow,
			title: <GetVerified>Verification in progress</GetVerified>,
		};
	}

	if (!user.isVerified && !isViewer) {
		return {
			isVisible: true,
			color: palette.light.primary.main,
			title: 'Verification pending',
			textColor: 'white',
		};
	}

	if (
		!isViewer &&
		isCurrentUser &&
		user.approved &&
		personalDetails &&
		personalDetails.personal?.status !== QuestionnaireStatus.DONE &&
		!personalDetails.personal?.isSubmitted
	) {
		const handleConfirmDetails = async () => {
			dispatch(openModal({ name: QuestionnaireTransactionEnum.personal_details, payload: { userId: user.uid } }));
		};

		return {
			isVisible: true,
			color: palette.light.primary.neonYellow,
			title: (
				<GetVerified>
					<button className='link' type='button' onClick={handleConfirmDetails}>
						Confirm your details
					</button>
				</GetVerified>
			),
		};
	}

	if (user.approved && personalDetails && personalDetails.personal?.status !== QuestionnaireStatus.DONE) {
		return {
			isVisible: true,
			color: palette.light.primary.main,
			title: 'Personal details pending',
			textColor: 'white',
		};
	}

	if (isViewer && user.offer && user.offer.isActive && (transaction.isSeller || transaction.isSellSideAgent)) {
		return {
			isVisible: true,
			color: palette.light.primary.main,
			title: (
				<GetVerified color='white'>
					<button className='link' type='button' onClick={handleAcceptOfferBySeller}>
						Accept
					</button>
					{' | '}
					<button className='link' type='button' onClick={handleRejectOfferBySeller}>
						Reject
					</button>
					{' | '}
					<button className='link' type='button' onClick={handleCounterofferBySeller}>
						Counteroffer
					</button>
				</GetVerified>
			),
		};
	}

	if (isViewer && user.counterOffer && user.counterOffer.isActive && isCurrentUser && transaction.isBuyerViewer) {
		return {
			isVisible: true,
			color: palette.light.primary.main,
			title: (
				<GetVerified color='white'>
					<button className='link' type='button' onClick={handleAcceptOfferByViewer}>
						Accept
					</button>
					{' | '}
					<button className='link' type='button' onClick={handleRejectOfferByViewer}>
						Reject
					</button>
					{' | '}
					<button className='link' type='button' onClick={handleCounterofferByViewer}>
						Counteroffer
					</button>
				</GetVerified>
			),
		};
	}

	const isAskPriceRejected = !!transaction.askPrice?.rejectedBy.some((u) => u === currentUser.uid);

	if (
		transaction.isBuyerViewer &&
		transaction.askPrice &&
		!transaction.myOffer &&
		!isAskPriceRejected &&
		isCurrentUser
	) {
		const handleAccept = () => dispatch(openModal({ name: modals.acceptAskPrice }));
		const handleCounteroffer = () => dispatch(openModal({ name: modals.offerModal }));
		const handleReject = async () => {
			if (!transaction.askPrice) return;

			await dispatch(rejectAskPrice({ id: transaction.askPrice.id }));
			alert('Ask price rejected!');
		};

		return {
			isVisible: true,
			color: palette.light.primary.main,
			title: (
				<GetVerified color='white'>
					<button className='link' type='button' onClick={handleAccept}>
						Accept
					</button>
					{' | '}
					<button className='link' type='button' onClick={handleReject}>
						Reject
					</button>
					{' | '}
					<button className='link' type='button' onClick={handleCounteroffer}>
						Counteroffer
					</button>
				</GetVerified>
			),
		};
	}

	if (isViewer && !user.offer) {
		return { isVisible: true, color: palette.light.primary.main, title: 'Offer pending', textColor: 'white' };
	}

	return { isVisible: false };
};

const handleCopy = (value: string, alert: NotistackAlert) => () => {
	alert('Data copied to clipboard');
	navigator.clipboard.writeText(value);
};

export const getUserBranchInfoOptions = (user: LocalUser, alert: NotistackAlert): IInviteCardTooltipFields[] => {
	if (!user.branch || typeof user.branch === 'string') return [];

	const list: IInviteCardTooltipFields[] = [];

	if (typeof user.branch?.telephone === 'string') {
		list.push({
			icon: 'ic:round-phone',
			value: user.branch.telephone,
			onCopy: handleCopy(user.branch.telephone, alert),
		});
	}

	if (user.branch?.email?.[0]) {
		list.push({
			icon: 'material-symbols:mail-rounded',
			value: user.branch.email[0],
			onCopy: handleCopy(user.branch.email[0], alert),
		});
	}

	if (user.branch?.website) {
		list.push({
			icon: 'mdi:web',
			value: '',
			label: (
				<Typography
					fontWeight={500}
					component='a'
					href={user.website}
					target='_blank'
					rel='noreferrer'
					whiteSpace='nowrap'
				>
					{user.branch.website}
				</Typography>
			),
			onCopy: handleCopy(user.branch.website, alert),
		});
	}

	if (user.branch?.address?.formattedAddress) {
		list.push({
			icon: 'material-symbols:location-on',
			value: user.branch.address.formattedAddress,
			onCopy: handleCopy(user.branch.address.formattedAddress, alert),
		});
	}

	return list;
};

export const getUserInfoOptions = (
	user: LocalUser,
	transactionQuestionnaire: IQuestionnaireTransaction,
	alert: NotistackAlert,
): IInviteCardTooltipFields[] => {
	const list: IInviteCardTooltipFields[] = [];

	const personalDetails = transactionQuestionnaire?.personal_details?.[user.uid];

	if (user.givenNames || user.lastNames) {
		const name = [user.givenNames, user.lastNames].filter(Boolean).join(' ');

		list.push({ icon: 'mdi:user', value: name, onCopy: handleCopy(name, alert) });
	}

	if (user.email) {
		list.push({ icon: 'material-symbols:mail-rounded', value: user.email, onCopy: handleCopy(user.email, alert) });
	}

	if (user.website) {
		list.push({
			icon: 'material-symbols:globe',
			value: '',
			label: (
				<Typography
					fontWeight={500}
					component='a'
					href={user.website}
					target='_blank'
					rel='noreferrer'
					whiteSpace='nowrap'
				>
					{user.website}
				</Typography>
			),
			onCopy: handleCopy(user.website, alert),
		});
	}

	if (typeof user.phone === 'string' && user.phone) {
		list.push({ icon: 'fluent:phone-32-regular', value: user.phone, onCopy: handleCopy(user.phone, alert) });
	}

	if (typeof user.landline === 'string' && user.landline) {
		list.push({ icon: 'ic:round-phone', value: user.landline, onCopy: handleCopy(user.landline, alert) });
	}

	const address = typeof user.address === 'string' ? user.address : user.address?.formattedAddress;

	if (address) {
		list.push({
			icon: 'ion:home',
			label: 'Home:',
			value: address,
			onCopy: handleCopy(address, alert),
		});
	}

	const preCompletionAddress = personalDetails?.personal?.correspondence?.address_pre_completion;

	if (preCompletionAddress) {
		const value = [
			preCompletionAddress.street_address,
			preCompletionAddress.postcode,
			preCompletionAddress.town_city,
			preCompletionAddress.county_province,
			preCompletionAddress.state_region,
			preCompletionAddress.country,
		]
			.filter(Boolean)
			.join(', ');

		if (value) {
			list.push({ icon: 'material-symbols:mail-rounded', label: 'Mail:', value, onCopy: handleCopy(value, alert) });
		}
	}

	return list;
};
