import { useSelector } from 'react-redux';
import { ConfirmationModal } from 'src/components/modals';
import modals from 'src/constants/modals';
import { selectUser } from 'src/redux/slices/auth';
import { selectProperty } from 'src/redux/slices/property';
import { selectTransactionParticipantsField, skipGiftorWelcomeModalThunk } from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { handleCloseModal } from 'src/utils/propertyHelpers';

const GiftorWelcomeModal = () => {
	const user = useSelector(selectUser);
	const participants = useSelector(selectTransactionParticipantsField);
	const property = useSelector(selectProperty);

	if (!participants.data || !property) return null;

	const buyers = participants.data.buyers.filter((b) => b.relationship === 'buyer' || !b.relationship);

	const handleContinue = async () => {
		dispatch(skipGiftorWelcomeModalThunk());

		handleCloseModal();
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '600px' }}
			modalName={modals.giftorWelcome}
			title={`Hi ${user.knownAs ?? user.givenNames}`}
			description={
				<>
					Welcome to Conveyo!{' '}
					<b>
						{buyers.map((b, index) => {
							const name = b.knownAs ?? b.givenNames;
							const isLast = index + 1 === buyers.length;
							const isFirst = index === 0;

							if (isLast && !isFirst) return `, and ${name}`;

							return `${isFirst ? '' : ', '}${name}`;
						})}
					</b>{' '}
					{buyers.length > 1 ? 'have' : 'has'} noted a gift from you for the purchase of{' '}
					<b>{property.address.displayAddress}</b>.<br />
					<br /> To make sure your gift meets regulatory requirements, please head to the <b>Overview tab</b> and follow
					the steps in <b>Your Tasks</b>.
				</>
			}
			withCancelButton={false}
			confirmText='Continue'
			handleClose={handleContinue}
			handleConfirm={handleContinue}
		/>
	);
};

export default GiftorWelcomeModal;
